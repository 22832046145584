import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService } from '@core/service/api';
import { AnalyticsOrders, ParamsAnalyticsOrders } from './interface';
import {
    OrdersMonths,
    OrdersMonthsService,
    ParamsOrdersMonths,
} from '@shared/service/methods/orders';

@Injectable({
    providedIn: 'any',
})
export class ApiAnalyticsOrdersService implements OrdersMonthsService {
    private _apiService = inject(ApiService);

    getReport(
        obj?: ParamsAnalyticsOrders,
        needPagination?: boolean,
        acceptableValues?: string[]
    ): Observable<AnalyticsOrders> {
        return this._apiService
            .get(
                'brand-lk/v1/analytics/orders',
                this._apiService.prepareParams(
                    obj,
                    needPagination,
                    acceptableValues
                )
            )
            .pipe(map((res) => res.json));
    }

    months(obj?: ParamsOrdersMonths): Observable<OrdersMonths> {
        return this._apiService
            .get('brand-lk/v1/analytics/orders/months', obj)
            .pipe(map((res) => res.json));
    }

    download(obj?: ParamsAnalyticsOrders): Observable<void> {
        return this._apiService
            .get('brand-lk/v1/analytics/orders/download', obj, true)
            .pipe(
                map((res) =>
                    this._apiService.downloadFile(res, 'ordered_items')
                )
            );
    }
}
